<template>
  <v-row 
    no-gutters
    class="app--full-height d-flex align-center justify-center"
  >
    <v-col 
      cols="12"
      md="3"
      xl="3"
    >
      <v-card outlined class="rounded-xl mask">
        <v-card-text class="px-5 py-10">
          <div class="text-center pb-3">
            <p class="text--logo">
              Fortune Connect
            </p>

            <v-avatar
              class="mt-2"
              size="55"
              tile
            >
              <img
                src="/logo.jpeg"
                alt="Logo"
              >
            </v-avatar>
          </div>
          <router-view />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {}
</script>